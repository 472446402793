<template>
  <div class="createInvoice">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">Finance Dashboard</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading" class="mt-2 px-5 pb-3">
        <v-row class="py-5">
          <v-col cols="4">
            <v-select
              v-model="selectedWeek"
              :items="weeksList"
              :item-text="(item) => `${item.weekNumber} - ${item.weekEnding}`"
              item-value="weekEnding"
              label="Select A Week"
              outlined
              dense
              @change="changeWeek"
            ></v-select>
          </v-col>
          <v-col cols="3"> </v-col>
          <v-col cols="5" align="center">
            <v-btn
              class="ml-1 mr-1 white--text"
              :loading="loading"
              :disabled="loading"
              color="red"
              @click="generatePdf()"
              >Generate PDF
              <v-icon>mdi-file-pdf</v-icon>
            </v-btn>
            <v-btn
              class="ml-1 mr-1 white--text"
              :loading="loading"
              :disabled="loading"
              color="teal"
              @click="getFinanceData(true)"
              >Export Report <v-icon dark> mdi-download </v-icon></v-btn
            >
          </v-col>
        </v-row>

        <!-- Charts container -->
        <v-card outlined class="mt-2 my-2 pa-2">
          <div id="chartsContainer">
            <!-- Loop through reports -->
            <v-row v-for="report in reportsArray" v-bind:key="report.title">
              <v-col cols="5">
                <v-row class="mt-5 px-3">
                  <v-col cols="12">
                    <canvas
                      :id="report.gaugeRef"
                      :ref="report.gaugeRef"
                    ></canvas>
                  </v-col>
                </v-row>
                <v-row v-if="quarterSummaryData">
                  <v-col cols="1" class="my-0 py-0">0</v-col>
                  <v-col cols="11" class="my-0 py-0" align="right"
                    >{{ quarterSummaryData.max[report.dataKey] }}
                  </v-col>
                  <v-col cols="12" align="center">
                    <h1
                      class="title"
                      style="
                        font-size: 1.25rem !important;
                        line-height: 2rem;
                        letter-spacing: 0.0125em !important;
                        font-family: 'Roboto', sans-serif !important;
                      "
                    >
                      <b
                        >{{ report.title }}: Week
                        {{ quarterSummaryData.current.Week }} -
                        {{ quarterSummaryData.current.Year }}</b
                      >
                    </h1>
                  </v-col>
                  <v-col cols="12" v-if="quarterSummaryData">
                    <v-row>
                      <v-col cols="3" align="center">
                        <p class="pb-0 mb-0"><b>Minimum:</b></p>
                        <p class="text-no-wrap" v-if="report.currency">
                          {{
                            formatCurrencyString(
                              quarterSummaryData.min[report.dataKey]
                            )
                          }}
                        </p>
                        <p class="text-no-wrap" v-else>
                          {{ quarterSummaryData.min[report.dataKey] }}
                        </p>
                      </v-col>
                      <v-col cols="3" align="center">
                        <p class="pb-0 mb-0"><b>Average:</b></p>
                        <p class="text-no-wrap" v-if="report.currency">
                          {{
                            formatCurrencyString(
                              quarterSummaryData.average[report.dataKey]
                            )
                          }}
                        </p>
                        <p class="text-no-wrap" v-else>
                          {{ quarterSummaryData.average[report.dataKey] }}
                        </p>
                      </v-col>
                      <v-col cols="3" align="center">
                        <p class="pb-0 mb-0"><b>Maximum:</b></p>
                        <p class="text-no-wrap" v-if="report.currency">
                          {{
                            formatCurrencyString(
                              quarterSummaryData.max[report.dataKey]
                            )
                          }}
                        </p>
                        <p class="text-no-wrap" v-else>
                          {{ quarterSummaryData.max[report.dataKey] }}
                        </p>
                      </v-col>
                      <v-col cols="3" align="center">
                        <p class="pb-0 mb-0"><b>Current:</b></p>
                        <p class="text-no-wrap" v-if="report.currency">
                          {{
                            formatCurrencyString(
                              quarterSummaryData.current[report.dataKey]
                            )
                          }}
                        </p>
                        <p class="text-no-wrap" v-else>
                          {{ quarterSummaryData.current[report.dataKey] }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="7">
                <line-chart
                  ref="lineChart"
                  v-if="report.chartData"
                  v-bind:chartData="report.chartData"
                  :styles="chartHeight"
                  :options="selectChartOptions(report.dataKey, report.currency)"
                >
                </line-chart>
                <v-row class="px-2">
                  <v-col cols="1"></v-col>
                  <v-col cols="10"  class="py-0 my-0">
                    <v-textarea
                    class="pa-0 ma-0"
                      v-model="commentsObj[report.dataKey]"
                      readonly
                      no-resize
                      outlined
                      rows="3"
                      row-height="5"
                    >
                    </v-textarea>
                  </v-col>
                  <v-col cols="1" class="px-0 mx-0">
                    <v-btn
                      icon
                      :loading="loading"
                      :disabled="loading"
                      color="primary"
                      @click="addFinanceComment(report.dataKey, report.title)"
                      ><v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                    <v-btn
                    v-if="commentsObj[report.dataKey]"
                      icon
                      :loading="loading"
                      :disabled="loading"
                      color="red"
                      @click="deleteFinanceComment(report.dataKey)"
                      ><v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" v-if="report.title !== 'Locations Occupied'">
                <v-divider class="ma-0 pa-0" />
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-card>
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{selectedWeek}} - {{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                  ref="commentField"
                    label="Comment"
                    required
                    v-model="dialogCommentField"
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="resetCommentMenu"
              :loading="loading"
              :disabled="loading"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="saveFinanceComment"
              :loading="loading"
              :disabled="loading"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar v-model="snackbar" :timeout="3000">
        {{ text }}
        <v-btn color="blue" text @click="snackbar = false"></v-btn>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import FinanceService from "@/services/Finance.js";
import * as XLSX from 'xlsx'
import LineChart from "../components/LineChart.vue";
import GaugeChart from "chartjs-gauge";
import htmlPdf from "html2pdf.js";
export default {
  components: { LineChart },
  data() {
    return {
      // Loading
      loading: false,
      // Snackbar
      snackbar: false,
      text: "",
      response: null,
      search: "",

      dialog: false,
      dialogTitle: "",
      dialogReportReference: "",
      dialogCommentField: "",

      excelCurrencyFormat: '"£"#,##0.00_);[Red]\\("£"#,##0.00\\)',

      financeData: [],
      quarterData: [],
      quarterSummaryData: null,
      //weeks dropdown
      weeksList: [],
      selectedWeek: null,
      testArray: {},

      commentsObj: {},

      reportsArray: [
        {
          chartData: null,
          title: "Total Revenue",
          labelKey: '"Wk/Ending',
          dataKey: "Total Rev",
          gaugeOrientation: "negToPos",
          gaugeRef: "totalRevGauge",
          currency: true,
        },
        {
          chartData: null,
          title: "Distribution Revenue",
          labelKey: '"Wk/Ending',
          dataKey: "Distribution Revenue",
          gaugeOrientation: "negToPos",
          gaugeRef: "dRevPerOrdGauge",
          currency: true,
        },
        {
          chartData: null,
          title: "Storage Revenue",
          labelKey: '"Wk/Ending',
          dataKey: "Storage",
          gaugeOrientation: "negToPos",
          gaugeRef: "storageRevenueGauge",
          currency: true,
        },
        {
          chartData: null,
          title: "Direct Revenue",
          labelKey: '"Wk/Ending',
          dataKey: "Direct Revenue",
          gaugeOrientation: "negToPos",
          gaugeRef: "handlingRevenueGauge",
          currency: true,
        },
        {
          chartData: null,
          title: "Line Throughput",
          labelKey: '"Wk/Ending',
          dataKey: "Lines",
          gaugeOrientation: "negToPos",
          gaugeRef: "ordersGauge",
          currency: false,
        },

        {
          chartData: null,
          title: "Direct Hours Worked",
          labelKey: '"Wk/Ending',
          dataKey: "Man Hours",
          gaugeOrientation: "posToNeg",
          gaugeRef: "mhrGauge",
          currency: false,
        },
        {
          chartData: null,
          title: "Revenue Per Line",
          labelKey: '"Wk/Ending',
          dataKey: "Rev/Line",
          gaugeOrientation: "negToPos",
          gaugeRef: "revPerLineGauge",
          currency: true,
        },
        {
          chartData: null,
          title: "Direct Revenue Per Man Hour",
          labelKey: '"Wk/Ending',
          dataKey: "ActivityRev/MHr",
          gaugeOrientation: "negToPos",
          gaugeRef: "aRevPerMhrGauge",
          currency: true,
        },

        {
          chartData: null,
          title: "Locations Occupied",
          labelKey: '"Wk/Ending',
          dataKey: "Occuppied Locations",
          gaugeOrientation: "negToPos",
          gaugeRef: "occupiedLocationsGauge",
          currency: false,
        },
      ],

      gaugeColors: {
        negToPos: ["red", "yellow", "green"],
        posToNeg: ["green", "yellow", "red"],
      },

      colorList: [
        "#e6194B",
        "#3cb44b",
        "#ffe119",
        "#4363d8",
        "#f58231",
        "#42d4f4",
        "#f032e6",
        "#469990",
        "#dcbeff",
        "#800000",
        "#ffd8b1",
        "#000075",
        "#000000",
        "#911eb4",
        "#9A6324",
        "#bfef45",
        "#a9a9a9",
      ],

      reportsRangeTitle: "",

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 15,
            right: 15,
            top: 5,
            bottom: 20,
          },
        },
        scales: {
          xAxes: [{ ticks: { padding: 15 } }],
        },
      },
      storageChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 15,
            right: 15,
            top: 5,
            bottom: 20,
          },
        },
        scales: {
          xAxes: [{ ticks: { padding: 15 } }],

          yAxes: [
            {
              ticks: {
                min: 10000,
                max: 50000,
                userCallback: function (value) {
                  value = value.toString();
                  value = value.split(/(?=(?:...)*$)/);
                  value = value.join(",");
                  return "£" + value;
                },
              },
            },
          ],
        },
      },
      currencyChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 15,
            right: 15,
            top: 5,
            bottom: 20,
          },
        },
        scales: {
          xAxes: [{ ticks: { padding: 15 } }],

          yAxes: [
            {
              ticks: {
                userCallback: function (value) {
                  value = value.toString();
                  value = value.split(/(?=(?:...)*$)/);
                  value = value.join(",");
                  return "£" + value;
                },
              },
            },
          ],
        },
      },
    };
  },
  created() {
    this.reportsRangeTitle = "Last Quarter";
    this.getFinanceData();
  },
  computed:{
     chartHeight(){
       let height = { xs: "65vh", sm: "65vh", lg: "55vh", xl: "40vh" }[
        this.$vuetify.breakpoint.name
      ];
      return height ? {height: height} : {};
    },
  },
  methods: {
    formatExcelColumn(worksheet, col, fmt) {
      const range = XLSX.utils.decode_range(worksheet["!ref"]);
      // note: range.s.r + 1 skips the header row
      for (let row = range.s.r; row <= range.e.r; ++row) {
        const ref = XLSX.utils.encode_cell({ r: row, c: col });
        if (worksheet[ref] && worksheet[ref].t === "n") {
          worksheet[ref].z = fmt;
        }
      }
      return worksheet;
    },

    formatCurrency(number) {
      return Math.round(number * 100) / 100;
    },

    async getFinanceData(exportData) {
      this.loading = true;
      this.search = "";

      return FinanceService.getFinanceData()
        .then(
          ((response) => {
            if (exportData) {
              this.exportData(response.data);
            }
            this.formatData(response.data);
            this.snackbar = true;
            this.text = `Finance Data loaded`;
            this.loading = false;
            return true;
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return false;
        });
    },

    formatData(data, weekIndex) {
      //format data if weekIndex is not passed from dropdown menu of weeks
      if (!weekIndex) {
        let weeklyData = [];
        //weekly data
        for (let i = 0; i < data.length; i++) {
          let row = data[i];
          let tempObj = {
            "Wk/Ending": row.week_ending,
            Week: Math.round(row.week),
            Month: Math.round(row.month),
            Year: Math.round(row.year),
            "Occuppied Locations": Math.round(row.occupied_locations),
            "Total Locations": Math.round(row.total_locations),
            Utilisation: Math.round(row.utilisation),
            "Man Hours": Math.round(row.man_hours),
            "WH Revenue": Math.round(row.wh_revenue),
            "WRev/Mhr": Math.round(row.w_rev_per_mhr),
            Orders: Math.round(row.orders),
            Lines: Math.round(row.lines),
            Pieces: Math.round(row.pieces),
            "Distribution Revenue": Math.round(row.distribution_revenue),
            "DRev/Ord": Math.round(row.d_rev_per_ord),
            Revenue: Math.round(row.revenue),
            Storage: Math.round(row.storage),
            Handling: Math.round(row.handling),
            "Handling Per Man hour": Math.round(row.handling_per_mhr),
            "Handling Per Order": Math.round(row.handling_per_ord),
            "Total Rev": Math.round(row.total_rev),
            "Rev/Mhr": Math.round(row.rev_per_mhr),
            "Rev/Ord": Math.round(row.rev_per_ord),
            "Rev/Loc": Math.round(row.rev_per_loc),
            "Strev/Loc": Math.round(row.strev_per_loc),
            "Total Units In Stock": Math.round(row.total_units_stock),
            "Units Shipped Vs Stock": row.units_shipped_vs_stock,
            "Rev/Line": parseFloat(row.rev_per_line),
            "ActivityRev/MHr": parseFloat(row.activity_revenue_per_man_hour),
            "Direct Revenue": Math.round(row.direct_revenue),
          };
          weeklyData.push(tempObj);
        }
        this.financeData = weeklyData;
      }

      if (weekIndex) {
        this.quarterData = this.financeData.slice(
          Math.max(weekIndex - 12, 0),
          weekIndex
        );
      } else {
        this.quarterData = this.financeData.slice(
          Math.max(this.financeData.length - 12, 0)
        );
      }

      //populate dropdown menu if weekIndex is not passed from dropdown menu
      if (!weekIndex) {
        let weeksList = [];
        for (let i = 0; i < this.quarterData.length; i++) {
          let row = this.quarterData[i];
          weeksList.push({
            weekEnding: row["Wk/Ending"],
            weekNumber: row["Week"],
          });
        }
        this.weeksList = weeksList;
      }
      //change reports title
      if (weekIndex) {
        this.reportsRangeTitle = `Between Weeks ${
          this.quarterData[0]["Wk/Ending"]
        } and ${this.quarterData[this.quarterData.length - 1]["Wk/Ending"]} `;
      }

      this.selectedWeek =
        this.quarterData[this.quarterData.length - 1]["Wk/Ending"];
      //get comments for reports
      this.getFinanceComments();

      let min = {};
      let max = {};
      let average = {};
      for (let j = 0; j < this.quarterData.length; j++) {
        //compute mins and max
        let keys = Object.keys(this.quarterData[j]);
        for (let x = 0; x < keys.length; x++) {
          let key = keys[x];
          if (
            key !== "Days" &&
            key !== "Freq" &&
            key !== "Month" &&
            key !== "Week" &&
            key !== "Year"
          ) {
            //check properties exist
            if (!(key in min)) {
              min[key] = 0;
            }
            if (!(key in max)) {
              max[key] = 0;
            }
            if (!(key in average)) {
              average[key] = 0;
            }
            //get min
            if (j === 0 || this.quarterData[j][key] < min[key]) {
              min[key] = this.quarterData[j][key];
            }

            //get max
            if (this.quarterData[j][key] > max[key]) {
              max[key] = this.quarterData[j][key];
            }

            //sum results for average
            average[key] += parseFloat(this.quarterData[j][key]);
          }
        }
      }

      //calculate average row
      let objKeys = Object.keys(average);
      for (let x = 0; x < objKeys.length; x++) {
        let key = objKeys[x];
        if (
          key !== "Days" &&
          key !== "Freq" &&
          key !== "Month" &&
          key !== "Week" &&
          key !== "Year"
        ) {
          if (key === "Rev/Line" || key === "ActivityRev/MHr") {
            average[key] = this.formatCurrency(
              average[key] / this.quarterData.length
            );
          } else {
            average[key] = Math.round(average[key] / this.quarterData.length);
          }
        }
      }
      //compute mix max and average for lastTwelveWeeks
      let current = this.quarterData.slice(-1);
      current = current.map((a) => ({ ...a }));
      current = current[0];

      let summary = {};
      summary.current = current;
      summary.min = min;
      summary.max = max;
      summary.average = average;

      this.quarterSummaryData = summary;

      //generate chart data
      for (let i = 0; i < this.reportsArray.length; i++) {
        let reportObj = this.reportsArray[i];
        this.reportsArray[i].chartData = this.formatLineChartData(
          this.quarterData,
          reportObj.labelKey,
          reportObj.dataKey,
          `${reportObj.title}: ${this.reportsRangeTitle}`,
          this.colorList[i]
        );
      }

      this.generateGauges();
    },

    generateGauges() {
      for (let i = 0; i < this.reportsArray.length; i++) {
        let reportObj = this.reportsArray[i];
        let gaugeData = this.formatGaugeData(
          this.quarterSummaryData,
          reportObj.dataKey,
          0,
          this.gaugeColors[reportObj.gaugeOrientation]
        );
        this.drawGaugeChart(reportObj.gaugeRef, gaugeData, reportObj.currency);
      }
    },
    formatGaugeData(dataset, propertyKey, minValue, colors) {
      //first bar threshold is at around 55% of the total gauge
      let minThreshold = Math.round(dataset.max[propertyKey] * 0.5);
      let middleThreshold = Math.round(dataset.max[propertyKey] * 0.75);
      //previous: dataset.min[propertyKey], dataset.average[propertyKey]
      return [
        {
          value: dataset.current[propertyKey],
          minValue: minValue,
          data: [minThreshold, middleThreshold, dataset.max[propertyKey]],
          backgroundColor: colors,
        },
      ];
    },

    drawGaugeChart(canvasRef, dataset, currency) {
      let canvas = this.$refs[canvasRef][0];
      //clear canvas
      const context = canvas.getContext("2d");
      context.clearRect(0, 0, canvas.width, canvas.height);
      GaugeChart(canvas, {
        type: "gauge",
        data: {
          datasets: dataset,
        },
        options: {
          animation: {
            animateRotate: false,
            animateScale: false,
          },
          needle: {
            radiusPercentage: 2,
            widthPercentage: 3.2,
            lengthPercentage: 80,
            color: "rgba(0, 0, 0, 1)",
          },
          valueLabel: {
            display: true,
            formatter: (value) => {
              if (currency) {
                return "£" + value;
              } else {
                return value;
              }
            },
            color: "rgba(255, 255, 255, 1)",
            backgroundColor: "rgba(0, 0, 0, 1)",
            borderRadius: 5,
            padding: {
              top: 10,
              bottom: 10,
            },
          },
        },
      });
    },
    formatLineChartData(objArray, labelsKey, dataKey, title, borderColor) {
      let labels = [];
      let dataVals = [];
      for (let i = 0; i < objArray.length; i++) {
        let row = objArray[i];
        labels.push([row.Year, row.Week]);
        dataVals.push(row[dataKey]);
      }

      let dataset = [
        {
          label: title,
          data: dataVals,
          fill: false,
          borderColor: borderColor,
          tension: 0.1,
        },
      ];
      return {
        labels: labels,
        datasets: dataset,
      };
    },
    selectChartOptions(dataKey, currency) {
      if (dataKey === "Storage") {
        return this.storageChartOptions;
      } else {
        return currency ? this.currencyChartOptions : this.chartOptions;
      }
    },

    exportData(data) {
      let weeklyData = [];

      //weekly data
      for (let i = 0; i < data.length; i++) {
        let row = data[i];
        let tempObj = {
          "Wk/Ending": row.week_ending,
          Week: parseInt(row.week ? row.week : 0),
          Month: parseInt(row.month ? row.month : 0),
          Year: parseInt(row.year ? row.year : 0),
          "Occuppied Locations": parseInt(row.occupied_locations ? row.occupied_locations : 0),
          "Total Locations": parseInt(row.total_locations ? row.total_locations : 0),
          Utilisation: parseFloat(row.utilisation ? row.utilisation : 0),
          "Man Hours": parseInt(row.man_hours ? row.man_hours : 0),
          "WH Revenue": parseFloat(row.wh_revenue ? row.wh_revenue : 0),
          "WRev/Mhr": parseFloat(row.w_rev_per_mhr ? row.w_rev_per_mhr : 0),
          Orders: parseInt(row.orders ? row.orders : 0),
          Lines: parseInt(row.lines ? row.lines : 0),
          Pieces: parseInt(row.pieces ? row.pieces : 0),
          "Distribution Revenue": parseFloat(row.distribution_revenue ? row.distribution_revenue : 0),
          "DRev/Ord": parseFloat(row.d_rev_per_ord ? row.d_rev_per_ord : 0),
          "Freighter Revenue": parseFloat(row.revenue ? row.revenue : 0),
          Storage: parseFloat(row.storage ? row.storage : 0),
          Handling: parseFloat(row.handling ? row.handling : 0),
          "Handling Per Man hour": parseFloat(row.handling_per_mhr ? row.handling_per_mhr : 0),
          "Handling Per Order": parseFloat(row.handling_per_ord ? row.handling_per_ord : 0),
          "Total Rev": parseFloat(row.total_rev ? row.total_rev : 0),
          "Rev/Mhr": parseFloat(row.rev_per_mhr ? row.rev_per_mhr : 0),
          "Rev/Ord": parseFloat(row.rev_per_ord ? row.rev_per_ord : 0),
          "Rev/Loc": parseFloat(row.rev_per_loc ? row.rev_per_loc : 0 ),
          "Strev/Loc": parseFloat(row.strev_per_loc ? row.strev_per_loc : 0),
          "Total Units In Stock": parseInt(row.total_units_stock ? row.total_units_stock : 0),
          "Units Shipped Vs Stock": row.units_shipped_vs_stock ? row.units_shipped_vs_stock : 0,
          "Admin Revenue": row.admin_revenue ? row.admin_revenue : 0 
        };
        weeklyData.push(tempObj);
      }
      //write to excel file
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(weeklyData);
      for (let col of [8, 9, 13, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]) {
        this.formatExcelColumn(ws, col, this.excelCurrencyFormat);
      }

      XLSX.utils.book_append_sheet(wb, ws, "WeeklyData");

      //second sheet
      let lastTwelveWeeks = weeklyData.slice(
        Math.max(weeklyData.length - 12, 0)
      );

      let min = {
        Year: "Min",
        Locations: 0,
        Utilisation: 0,
        "Man Hours": 0,
        "WH Revenue": 0,
        "Distribution Revenue": 0,
        "Freighter Revenue": 0,
        Total: 0,
        Orders: 0,
        Lines: 0,
        Pieces: 0,
        "TRev / Mhr": 0,
        "WRev/Mhr": 0,
        "DRev/Ord": 0,
        "TRev/Loc": 0,
        Storage: 0,
        Handling: 0,
        "Handling Per Man hour": 0,
        "Handling Per Order": 0,
        "Admin Revenue" : 0,
      };

      let max = {
        Year: "Max",
        Locations: 0,
        Utilisation: 0,
        "Man Hours": 0,
        "WH Revenue": 0,
        "Distribution Revenue": 0,
        "Freighter Revenue": 0,
        Total: 0,
        Orders: 0,
        Lines: 0,
        Pieces: 0,
        "TRev / Mhr": 0,
        "WRev/Mhr": 0,
        "DRev/Ord": 0,
        "TRev/Loc": 0,
        Storage: 0,
        Handling: 0,
        "Handling Per Man hour": 0,
        "Handling Per Order": 0,
        "Admin Revenue" : 0,
      };

      let average = {
        Year: "Average",
        Locations: 0,
        Utilisation: 0,
        "Man Hours": 0,
        "WH Revenue": 0,
        "Distribution Revenue": 0,
        "Freighter Revenue": 0,
        Total: 0,
        Orders: 0,
        Lines: 0,
        Pieces: 0,
        "TRev / Mhr": 0,
        "WRev/Mhr": 0,
        "DRev/Ord": 0,
        "TRev/Loc": 0,
        Storage: 0,
        Handling: 0,
        "Handling Per Man hour": 0,
        "Handling Per Order": 0,
        "Admin Revenue" : 0,
      };

      for (let j = 0; j < lastTwelveWeeks.length; j++) {
        delete lastTwelveWeeks[j]["Wk/Ending"];
        lastTwelveWeeks[j].Days = 84 - 7 * j;
        lastTwelveWeeks[j].Freq = j + 1;

        //rename columns
        lastTwelveWeeks[j]["TRev / Mhr"] = lastTwelveWeeks[j]["Rev/Mhr"];
        delete lastTwelveWeeks[j]["Rev/Mhr"];

        lastTwelveWeeks[j]["Total"] = lastTwelveWeeks[j]["Total Rev"];
        delete lastTwelveWeeks[j]["Total Rev"];

        lastTwelveWeeks[j]["Locations"] =
          lastTwelveWeeks[j]["Occuppied Locations"];
        delete lastTwelveWeeks[j]["Occuppied Locations"];

        lastTwelveWeeks[j]["TRev/Loc"] = lastTwelveWeeks[j]["Rev/Loc"];
        delete lastTwelveWeeks[j]["Rev/Loc"];

        //delete unused columns
        delete lastTwelveWeeks[j]["Total Locations"];
        delete lastTwelveWeeks[j]["Rev/Ord"];
        delete lastTwelveWeeks[j]["Strev/Loc"];
        delete lastTwelveWeeks[j]["Total Units In Stock"];
        delete lastTwelveWeeks[j]["Units Shipped Vs Stock"];
        delete lastTwelveWeeks[j]["Revenue"];

        //compute mins and max
        let keys = Object.keys(lastTwelveWeeks[j]);
        for (let x = 0; x < keys.length; x++) {
          let key = keys[x];
          if (
            key !== "Days" &&
            key !== "Freq" &&
            key !== "Month" &&
            key !== "Week" &&
            key !== "Year"
          ) {
            //get min

            if (j === 0 || lastTwelveWeeks[j][key] < min[key]) {
              min[key] = lastTwelveWeeks[j][key];
            }
            //get max
            if (lastTwelveWeeks[j][key] > max[key]) {
              max[key] = lastTwelveWeeks[j][key];
            }

            //sum results for average
            average[key] += parseFloat(lastTwelveWeeks[j][key]);
          }
        }
      }

      //calculate average row
      let objKeys = Object.keys(average);
      for (let x = 0; x < objKeys.length; x++) {
        let key = objKeys[x];
        if (
          key !== "Days" &&
          key !== "Freq" &&
          key !== "Month" &&
          key !== "Week" &&
          key !== "Year"
        ) {
          if (
            key === "Locations" ||
            key === "Orders" ||
            key === "Lines" ||
            key === "Pieces" ||
            key === "Man Hours"
          ) {
            average[key] = Math.round(average[key] / lastTwelveWeeks.length);
          } else {
            average[key] = this.formatCurrency(
              average[key] / lastTwelveWeeks.length
            );
          }
        }
      }
      //compute mix max and average for lastTwelveWeeks
      let current = lastTwelveWeeks.slice(-1);
      current = current.map((a) => ({ ...a }));
      current = current[0];
      current.Year = "Current";
      current.Days = "";
      current.Freq = "";
      current.Month = "";
      current.Week = "";

      let summary = [];
      summary.push(current);
      summary.push(min);
      summary.push(max);
      summary.push(average);

      let cols = [
        "Days",
        "Freq",
        "Month",
        "Week",
        "Year",
        "Locations",
        "Utilisation",
        "Man Hours",
        "WH Revenue",
        "Distribution Revenue",
        "Freighter Revenue",
        "Total",
        "Orders",
        "Lines",
        "Pieces",
        "TRev / Mhr",
        "WRev/Mhr",
        "DRev/Ord",
        "TRev/Loc",
        "Storage",
        "Handling",
        "Handling Per Man hour",
        "Handling Per Order",
        "Admin Revenue"
      ];

      //write summary and last twelve weeks on same sheet
      let ws2 = XLSX.utils.json_to_sheet(summary, {
        header: cols,
        skipHeader: true,
      });
      XLSX.utils.sheet_add_json(ws2, lastTwelveWeeks, {
        header: cols,
        origin: "A6",
      });

      //format columns for excel
      //currency format

      for (let col of [8, 9, 10, 11, 15, 16, 17, 18, 19, 20, 21, 22]) {
        ws2 = this.formatExcelColumn(ws2, col, this.excelCurrencyFormat);
      }

      XLSX.utils.book_append_sheet(wb, ws2, "Last12Weeks");

      XLSX.writeFile(wb, "financeData.xlsx");
    },

    changeWeek(input) {
      let newCurrentIndex = null;
      for (let i = 0; i < this.financeData.length; i++) {
        let row = this.financeData[i];
        if (row["Wk/Ending"] === input) {
          newCurrentIndex = i + 1;
        }
      }
      if (newCurrentIndex) {
        this.formatData(this.financeData, newCurrentIndex);
      }
    },
    getFinanceComments() {
      for(let i =0; i< this.reportsArray.length; i++){
        this.$set(this.commentsObj,this.reportsArray[i].dataKey , '');
      }
      FinanceService.getFinanceComments(this.selectedWeek)
        .then((response) => {
          if (response.success && response.success == 200) {
            for (let i = 0; i < response.data.length; i++) {
              let reportReference = response.data[i].report_reference;
              let comment = response.data[i].comment;
              //using set so the components are reactive as well
              this.$set(this.commentsObj,reportReference , comment);
            }
      
          }
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return false;
        });
    },
    deleteFinanceComment(reportKey) {
      FinanceService.deleteFinanceComment(this.selectedWeek, reportKey)
        .then((response) => {
          this.loading = false;
          if (response.success && response.success == 200) {
            this.snackbar = true;
            this.text = response.data;
            this.getFinanceComments()
          }
        })
        .catch((error) => {
          this.resetCommentMenu();
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return false;
        });
    },
    addFinanceComment(reportKey, reportTitle) {
      this.dialog = true;
      this.dialogReportReference = reportKey;
      this.dialogTitle = reportTitle;
      this.dialogCommentField = this.commentsObj[reportKey] ? this.commentsObj[reportKey] : '';
     
       this.$nextTick(() => {
         this.$refs.commentField.focus();
      });
    },
    saveFinanceComment() {
      let weekEnding = this.selectedWeek;
      let reportReference = this.dialogReportReference;
      let comment = this.dialogCommentField;
      if (!weekEnding) {
        this.snackbar = true;
        this.text = `WeekEnding Required`;
      } else if (!reportReference) {
        this.snackbar = true;
        this.text = `ReportReference Required`;
      } else if (!comment) {
        this.snackbar = true;
        this.text = `Comment Required`;
      }
      this.loading = true;
      let formDate = {
        week_ending: weekEnding,
        report_reference: reportReference,
        comment: comment,
      };
      FinanceService.saveFinanceComment(formDate)
        .then((response) => {
          this.loading = false;
          this.resetCommentMenu();
          if (response.success && response.success == 200) {
            this.snackbar = true;
            this.text = response.data;
            this.getFinanceComments()
          }
        })
        .catch((error) => {
          this.resetCommentMenu();
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return false;
        });
    },
    resetCommentMenu() {
      this.dialog = false;
      this.dialogTitle = "";
      this.dialogReportReference = "";
      this.dialogCommentField = "";
    },
    formatCurrencyString(value) {
      if (value) {
        return "£" + value.toString().replace(/\d(?=(\d{3})+\.)/g, "$&,");
      } else {
        return "";
      }
    },
    generatePdf() {
      this.loading = true;
      //change options based on screen size
      let options = this.getPdfOptions();

      var element = document.getElementById("chartsContainer");
      htmlPdf()
        .set(options)
        .from(element)
        .save()
        .then(() => {
          this.loading = false;
        });
    },
    getPdfOptions() {
      //changes button sizes based on screen size
      let size = { xs: "small", sm: "small", lg: "small", xl: "large" }[
        this.$vuetify.breakpoint.name
      ];
      size = size ? size : "small";
      const opt = {
        small: {
          margin: [2, 2],
          filename: "financeCharts.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2, windowWidth: 1270 },
          jsPDF: { format: "a3", orientation: "portrait" },
        },
        large: {
          margin: [5, 3],
          filename: "financeCharts.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2, width: 1230 },
          jsPDF: { format: "a3", orientation: "portrait" },
        },
      };
      return opt[size];
    },
  },
};
</script>

<style lang="scss" >
.errorMessage {
  font-size: smaller;
  margin-top: 12px;
  p {
    margin-bottom: 0px;
  }
}

//show scrollbars on text areas
textarea::-webkit-scrollbar {
  width: 7px;
  background: transparent; /* make scrollbar transparent */
}

textarea::-webkit-scrollbar-thumb,
.v-menu__content::-webkit-scrollbar-thumb,
.v-dialog::-webkit-scrollbar-thumb,
.v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  border-radius: 4px;
}
.v-menu__content::-webkit-scrollbar,
.v-dialog::-webkit-scrollbar {
  width: 7px;
  background: white;
}


//limit margin inside v-textarea
.v-textarea.v-text-field--box.v-text-field--single-line:not(.v-input--dense) .v-text-field__prefix, .v-textarea.v-text-field--box.v-text-field--single-line:not(.v-input--dense) .v-text-field__suffix, .v-textarea.v-text-field--box.v-text-field--single-line:not(.v-input--dense) textarea, .v-textarea.v-text-field--box.v-text-field--outlined:not(.v-input--dense) .v-text-field__prefix, .v-textarea.v-text-field--box.v-text-field--outlined:not(.v-input--dense) .v-text-field__suffix, .v-textarea.v-text-field--box.v-text-field--outlined:not(.v-input--dense) textarea, .v-textarea.v-text-field--enclosed.v-text-field--single-line:not(.v-input--dense) .v-text-field__prefix, .v-textarea.v-text-field--enclosed.v-text-field--single-line:not(.v-input--dense) .v-text-field__suffix, .v-textarea.v-text-field--enclosed.v-text-field--single-line:not(.v-input--dense) textarea, .v-textarea.v-text-field--enclosed.v-text-field--outlined:not(.v-input--dense) .v-text-field__prefix, .v-textarea.v-text-field--enclosed.v-text-field--outlined:not(.v-input--dense) .v-text-field__suffix, .v-textarea.v-text-field--enclosed.v-text-field--outlined:not(.v-input--dense) textarea {
 margin-top: 4px;
}
</style>
