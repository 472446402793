<script>
import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;
export default {
  extends: Line,
  mixins: [reactiveProp],
  data() {
    return {
      gradients: null,
    };
  },
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.generateGradiets()
    this.renderChart(this.chartData, this.options);
  },
  watch: {
    chartData() {
      this._data._chart.destroy();
      this.renderChart(this.chartData, this.options);
    },
    options() {
      this._data._chart.destroy();
      this.renderChart(this.chartData, this.options);
    },

  },
  methods: {
    assignGradients() {
      if (this.chartData && this.chartData.datasets) {
        for (let i = 0; i < this.chartData.datasets.length; i++) {
          let index = i % this.gradients.length;
          this.chartData.datasets[i].backgroundColor = this.gradients[index];
        }
      }
    },
    generateGradiets() {
      this.gradients = [];
      this.gradients[0] = this.$refs.canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 450);
      this.gradients[1] = this.$refs.canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 450);
      this.gradients[2] = this.$refs.canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 450);
      this.gradients[3] = this.$refs.canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 450);
      this.gradients[4] = this.$refs.canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 450);
      this.gradients[5] = this.$refs.canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 450);
      this.gradients[0].addColorStop(0, "rgba(255, 0,0, 0.5)");
      this.gradients[0].addColorStop(0.5, "rgba(255, 0, 0, 0.25)");
      this.gradients[0].addColorStop(1, "rgba(255, 0, 0, 0)");

      this.gradients[1].addColorStop(0, "rgba(0, 231, 255, 0.9)");
      this.gradients[1].addColorStop(0.5, "rgba(0, 231, 255, 0.25)");
      this.gradients[1].addColorStop(1, "rgba(0, 231, 255, 0)");

      this.gradients[2].addColorStop(0, "rgba(233, 100, 255, 0.9)");
      this.gradients[2].addColorStop(0.5, "rgba(233, 100, 255, 0.25)");
      this.gradients[2].addColorStop(1, "rgba(233, 100, 255, 0)");

      this.gradients[3].addColorStop(0, "rgba(223, 233, 45, 0.9)");
      this.gradients[3].addColorStop(0.5, "rgba(223, 223, 45, 0.25)");
      this.gradients[3].addColorStop(1, "rgba(233, 233, 45, 0)");

      this.gradients[4].addColorStop(0, "rgba(155, 255, 55, 0.9)");
      this.gradients[4].addColorStop(0.5, "rgba(155, 255, 55, 0.25)");
      this.gradients[4].addColorStop(1, "rgba(155, 255, 55, 0)");

      this.gradients[5].addColorStop(0, "rgba(255, 155, 255, 0.9)");
      this.gradients[5].addColorStop(0.5, "rgba(255, 155, 255, 0.25)");
      this.gradients[5].addColorStop(1, "rgba(255, 155, 255, 0)");
    },
  },
};
</script>
